import React from 'react';

import Link from 'next/link';

import clsx from 'clsx';
import ChevronLeft from 'images/chevron-left.svg';
import Hamburger from 'images/solid/menu/hamburger.svg';

import { LinkedLogo } from 'components/Logo';

export default function Header({
  show = true,
  title,
  showHamburger = true,
  outletLeft,
  outletRight,
  children,
  sticky,
  onShowMenu = () => {},
}) {
  if (!show) return null;

  return (
    <div
      className={clsx('border-b bg-white', sticky && 'sticky top-0 z-[500]')}
    >
      <div className="container">
        <div className="flex justify-between items-center h-[56px]">
          {/* Mobile only: Left outlet */}
          <div className="shrink-0 w-20 flex justify-start md:hidden">
            {outletLeft ? (
              outletLeft
            ) : showHamburger ? (
              <button type="button" onClick={onShowMenu}>
                <Hamburger />
              </button>
            ) : null}
          </div>

          {/* Mobile only: Title or Logo */}
          <div className="grow min-w-0 flex items-center justify-center md:hidden">
            {title ? (
              <div className="text-center font-medium truncate">{title}</div>
            ) : (
              <LinkedLogo />
            )}
          </div>

          {/* Desktop only: Logo */}
          <div className="hidden md:flex grow">
            <LinkedLogo />
          </div>

          {showHamburger && children}

          {/* Mobile only: Right outlet */}
          <div className="shrink-0 w-20 flex justify-end md:hidden">
            {outletRight}
          </div>
        </div>
      </div>
    </div>
  );
}

export const BackButton = ({ href, children }) => (
  <Link href={href}>
    <a className="flex items-center min-w-0 md:text-gray-500 md:text-sm md:hover:underline">
      <ChevronLeft className="mr-2 md:mr-1 md:h-2 shrink-0" />
      <span className="block truncate tracking-tighter">{children}</span>
    </a>
  </Link>
);

export const CloseButton = ({ href, children }) => (
  <Link href={href}>
    <a className="flex items-center min-w-0 text-spoto">{children}</a>
  </Link>
);
