import Link from 'next/link';

import Spoto from 'images/spoto.svg';

export default function Logo(props) {
  return <Spoto {...props} />;
}

export const LinkedLogo = (props) => (
  <Link href="/">
    <a className="inline-block" {...props}>
      <Logo width="100" />
      <span className="sr-only">Spoto</span>
    </a>
  </Link>
);
