import React from 'react';

import { Dialog, Transition } from '@headlessui/react';
import useScrollLock from 'ctx/useScrollLock';
import X from 'images/solid/menu/x.svg';

export default function Drawer({ show, onClose = () => {}, children }) {
  useScrollLock(show);

  return (
    <Transition.Root show={show} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 h-100vh flex z-[2000] md:hidden"
        onClose={onClose}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-sm w-full bg-white">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 pt-6 pr-4">
                <button
                  type="button"
                  className="flex items-center justify-center h-10 w-10 rounded-full"
                  onClick={onClose}
                >
                  <span className="sr-only">Close drawer</span>
                  <X aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>

            {children}
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-10">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const Body = (props) => (
  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto" {...props} />
);

const Footer = (props) => (
  <div className="flex-shrink-0 flex border-t border-gray-200 p-4" {...props} />
);

Drawer.Body = Body;
Drawer.Footer = Footer;
