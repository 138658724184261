import React from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import clsx from 'clsx';
import useUser from 'ctx/useAuth';
import useScrollLock from 'ctx/useScrollLock';
import Home from 'images/solid/menu/home.svg';
import Profile from 'images/solid/menu/profile.svg';
import Reservations from 'images/solid/menu/reservations.svg';
import support from 'images/support.png';

import { startChat } from 'lib/chat';

import Credits from './Credits';
import Drawer from './Drawer';

const navigation = [
  { label: 'Home', icon: Home, route: '/', matchRoutes: ['/', '/map'] },
  { label: 'My Reservations', icon: Reservations, route: '/bookings' },
  { label: 'Profile', icon: Profile, route: '/profile' },
];

export default function MobileMenu({ show, onClose = () => {} }) {
  const router = useRouter();
  const { user } = useUser({ redirectTo: null });

  useScrollLock(show);

  const userName = user?.firstName;

  return (
    <Drawer show={show} onClose={onClose}>
      <Drawer.Body>
        <div className="pt-16 pb-6 px-4">
          <div className="px-4 space-y-6">
            <div className="text-4xl">Hello {userName}</div>

            <nav className="-mx-2 space-y-1">
              {navigation.map(
                ({ icon: IconComponent, label, route, matchRoutes }, idx) => (
                  <Link key={idx} href={route}>
                    <a
                      key={idx}
                      onClick={onClose}
                      className={clsx(
                        matchRoutes?.includes(router.pathname) ||
                          (router.pathname.startsWith(route) && route !== '/')
                          ? 'text-spoto'
                          : 'text-black',
                        'group flex items-center px-2 py-2 text-lg font-semibold rounded-md'
                      )}
                    >
                      <IconComponent className="mr-3" aria-hidden="true" />
                      {label}
                    </a>
                  </Link>
                )
              )}
            </nav>
          </div>

          {user && (
            <ul className="mt-8 flex rounded border divide-x py-4 text-center">
              <li className="px-2 py-0.5 w-full">
                <Credits type="available" credits={user.credits.balance} />
                <div className="text-sm">Credit left</div>
              </li>
              <li className="px-2 py-0.5 w-full">
                <Credits
                  type="expiring"
                  credits={user.credits.expiringSoon.amount}
                />
                <div className="text-sm">Expiring</div>
              </li>
            </ul>
          )}

          <div className="mt-8">
            <Support />
          </div>
        </div>
      </Drawer.Body>
    </Drawer>
  );
}

const Support = () => {
  return (
    <div className="relative grid grid-cols-12 gap-4 rounded-md bg-[#005AFF] bg-opacity-5 overflow-hidden">
      <div className="col-span-5">
        <div className="absolute -bottom-2 left-2">
          <Image
            objectFit="cover"
            quality={100}
            width={118}
            height={140}
            className=""
            placeholder="blur"
            src={support}
            alt=""
          />
        </div>
      </div>
      <div className="relative col-span-7 z-10">
        <div className="pt-8 pb-6 pr-4">
          <div className="text-lg font-semibold">Support</div>
          <div className="text-xs">
            Feel free to contact us with any question, issue or feedback you may
            have.
          </div>
          <button onClick={startChat} className="mt-2 text-base text-spoto">
            Chat now
          </button>
        </div>
      </div>
    </div>
  );
};
