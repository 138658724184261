import React from 'react';

export default function useScrollLock(locked) {
  const scrollPosition = React.useRef(null);

  React.useEffect(() => {
    if (locked) {
      scrollPosition.current = window.scrollY;
      document.documentElement.classList.add('is-locked');
    } else {
      document.documentElement.classList.remove('is-locked');
      window.scrollTo(0, scrollPosition.current);
    }

    return () => {
      document.documentElement.classList.remove('is-locked');
    };
  }, [locked]);
}
