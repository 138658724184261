import React from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import clsx from 'clsx';
import Profile from 'images/solid/menu/profile.svg';
import Reservations from 'images/solid/menu/reservations.svg';

import Header from 'components/Header';
import MobileMenu from 'components/MobileMenu';

const DesktopNavigation = () => {
  const router = useRouter();

  return (
    <nav className="hidden md:flex space-x-4">
      {[
        {
          label: 'My Reservations',
          icon: Reservations,
          route: '/bookings',
        },
        { label: 'Profile', icon: Profile, route: '/profile' },
      ].map(({ icon: IconComponent, label, route, matchRoutes }, idx) => (
        <Link key={idx} href={route}>
          <a
            key={idx}
            className={clsx(
              matchRoutes?.includes(router.pathname) ||
                (router.pathname.startsWith(route) && route !== '/')
                ? 'text-spoto'
                : 'text-black',
              'group flex items-center px-2 py-2 text-sm font-semibold rounded-md'
            )}
          >
            <IconComponent className="mr-3" aria-hidden="true" />
            {label}
          </a>
        </Link>
      ))}
    </nav>
  );
};

export default function Layout({ children, headerProps }) {
  const [show, setShow] = React.useState(false);

  return (
    <>
      <div className="flex flex-col min-h-100vh">
        <Header onShowMenu={() => setShow(true)} sticky {...headerProps}>
          <DesktopNavigation />
        </Header>
        <MobileMenu show={show} onClose={() => setShow(false)} />
        <main className="flex flex-col flex-1">{children}</main>
      </div>
    </>
  );
}

export const getLayout = (page) => <Layout>{page}</Layout>;
