import clsx from 'clsx';

// TODO: Use min-width instead of fixed width
export default function Credits({ credits, type = 'default', className }) {
  const variants = {
    default: 'text-black',
    expiring: 'text-red',
    available: 'text-spoto',
  };

  return (
    <div
      className={clsx(
        'inline-block w-12 text-lg font-semibold truncate',
        variants[type],
        className
      )}
    >
      {credits}
    </div>
  );
}
