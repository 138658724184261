import React from 'react';

import { useRouter } from 'next/router';

import { UserProfileQuery } from 'queries';
import { useQuery } from 'urql';

export default function useUser({
  redirectTo = '/auth/login',
  redirectIfFound = false,
} = {}) {
  const router = useRouter();
  const [result] = useQuery({
    query: UserProfileQuery,
  });

  const { data, fetching } = result;

  const user = data?.me;

  React.useEffect(() => {
    // if no redirect needed, just return
    // if user data not there yet (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || fetching) return;

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user)
    ) {
      router.push(redirectTo);
      return;
    }
  }, [user, fetching, redirectIfFound, redirectTo, router]);

  return { user };
}
