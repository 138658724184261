import { notify } from 'components/Notifications';

export const startChat = () => {
  if (window.drift) {
    // eslint-disable-next-line no-undef
    drift.api.openChat();
  } else {
    notify.error("Looks like we can't open a chat right now.");
  }
};
